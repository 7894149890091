import React from "react";
import Feature from "../../components/feature/Feature";
import "./CloudServices2.css";
import kartImage from "../../assets/1.jpg";

const CloudServices2 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="products">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="Our products"
        text="In addition to bespoke enterprise solutions AduroIT also creates consumer products where technology and process automation can bring vaule to a wide range of applications."
      />
    </div>
    <div className="gpt3__whatgpt3-heading_container">
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">Race Recorder</h1>
        <h4>
          Our race recorder product provides a wide range of features to track your progress
          and record your kart configuration while at the track.  The aim of the product is to help teams and individual racers
          track and record their progression and the setup of their kart to determine optimal perfromance of both driver and vehicle.
        </h4>
        <h4>Request beta access for a free trial</h4>
      </div>
      <div className="gpt3__whatgpt3-heading-img">
        <img src={kartImage} alt="Kart illustration" />
      </div>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature
        title="Record your kart setup"
        text="Record the setup of your kart before and after each track session."
      />
      <Feature
        title="Track your progress"
        text="Create a timeline of your driving progress and best lap times.  Compare your best kart setups."
      />
      <Feature
        title="Engine and carburetor setup"
        text="Record your engine hours and set alarms when maintenance is required and use our utilities for tuning your engine at the track"
      />
      <Feature
        title="Backup your data logger"
        text="Our cloud product will backup your compatible data logger to ensure your track data is never lost and completley recoverable"
      />
    </div>
  </div>
);

export default CloudServices2;
