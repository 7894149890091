import React from 'react';
import gpt3Logo from '../../assets/logo1.svg';
import './Footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Convert your business to cloud and maximise your potential</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p><a href="#home">Get in Touch</a></p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p> All Rights Reserved</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2022 ADUROIT. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;