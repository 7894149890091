import React, { useState } from "react";

import globe from "../../assets/globe2.png";
import "./Header.css";
import ErrorModal from "../../components/ui/ErrorModal";

const Header = () => {
  const [response, setResponse] = useState();
  const [enteredEmail, setEmail] = useState();

  const emailAddedHandler = (event) => {
    setEmail(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const email = {
      email: enteredEmail,
    };
    console.log(email);
    setEmail("");

    setResponse({
      title: "Email received",
      message: "We will be in touch shortly!",
    });
  };

  const responseHandler = () => {
    setResponse(null);
  };

  return (
    <div>
      {response && (
        <ErrorModal
          title={response.title}
          message={response.message}
          onConfirm={responseHandler}
        />
      )}
      <div className="gpt3__header section__padding" id="home">
        <div className="gpt3__header-content">
          <h1 className="gradient__text">
            Let&apos;s Build Something amazing with cloud services
          </h1>
          <p>
            From Enterprise to start-ups, we provide solutions with security and
            risk management as top priorities. Identifying business risk and
            applying solutions in a cost effective manner, within a defined
            budget and scope.
          </p>

          <form
            onSubmit={submitHandler}
            className="gpt3__header-content__input"
          >
            <input
              onChange={emailAddedHandler}
              value={enteredEmail}
              type="email"
              placeholder="Your Email Address"
              required
            />
            <button type="submit">Get In Touch</button>
          </form>
        </div>

        <div className="gpt3__header-image">
          <img src={globe} alt="globe illustration" />
        </div>
      </div>
    </div>
  );
};

export default Header;
