import React from 'react';
import './App.css'

import {Footer, Blog, Features, CloudServices, CloudServices2, Header } from './containers';
import {Brand, Navbar } from './components';

const App = () => {
  return ( 
    <div className='App'>
      <div className='gradient__bg'>
        <Navbar />
        <Header />
      </div>
      <Brand />
      <CloudServices />
      <Features />
      <CloudServices2 />
      <Blog />
      <Footer />
    </div>
   );
}
 
export default App;

