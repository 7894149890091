import React from "react";
import Feature from '../../components/feature/Feature';
import './CloudServices.css';

const CloudServices = () => (
    <div className="gpt3__whatgpt3 section__margin" id="services">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Our services" text="Where are you with your cloud journey? Are you looking to migrate a system from your data centre? Or maybe modernise a system already deployed to your cloud platform of choice? We can help you design and build simple and complex solutions that suits your requirements, risk profile and budget." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Our solutions are bespoke to your business requirements and maximise your business efficiency</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Design &amp; Development" text="Our technical team can design and develop bespoke solutions based on your requirements" />
      <Feature title="Process Automation" text="Evey product we design aims to deliver maximum return through automation of manual tasks and processes." />
      <Feature title="Strategy &amp; Architecture" text="We can provide consultancy to ensure your cloud strategy will meet your cloud aspirations and make your business vision a reality" />
      <Feature title="Cloud Migration" text="Our technical teams can plan and test and migrate your traditional data centre assets to the cloud with efficientcy." />
    </div>
  </div>
);
 
export default CloudServices;