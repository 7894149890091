import React from 'react';
import Feature from '../../components/feature/Feature';
import './Features.css';

const featuresData = [
  {
    title: 'Dynamic Scalability',
    text: 'Cloud based solutions provide great value for businesses with fluctuating resource requirements. If your demands increase, you can increase your cloud capacity without the need to invest in infrastructure.',
  },
  {
    title: 'Efficiency and Cost Reduction',
    text: 'Through use of cloud services, there is no need to procure physical infrastructure or maintain building facilities. This reduces operational spend and Total Cost of Ownership (TCO). In addition, specialist IT teams are no longer required to maintain legacy infrastructure or physical IT equipment',
  },
  {
    title: 'Gain greater innovation',
    text: 'Cloud providers invest heavyly in new services and customers can gain access to these services at a much faster rate than they would developing in house. This level of agility can give businesses using cloud computing a competitive advantage.',
  },
  {
    title: 'High Speed Quick Deployments',
    text: 'The ability to deploy new cloud computing instances in minutes enables system developers to easily test new deployments and design new architectures without the requirement to procure new hardware and or resources.  Once the requirement is tested the resources can be removed instantly without ongoing maintenance.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The cloud, when architected in the right way, reduces your delivery time to market and 
helps improve business agility, giving your business a competitive advantage.</h1>
      {/* <p>Move your business to cloud and reap the benefits now</p> */}
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;