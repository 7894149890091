import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './Imports';
import './Blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">We have a lot going on, <br /> check out our blogs.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} date="20 Feb, 2022" text="Separating your cloudformation for nested deployments" />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} date="18 Feb, 2022" text="Event driven CMDB automation with AWS services" />
        <Article imgUrl={blog03} date="02 Feb, 2022" text="Cloudwatch searching with JSON Lambda logging" />
        <Article imgUrl={blog04} date="10 Feb, 2022" text="How to Build a multi-region microservices architecture" />
        <Article imgUrl={blog05} date="26 Feb, 2022" text="Secrets manager for beginners" />
      </div>
    </div>
  </div>
);

export default Blog;